import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Label } from 'reactstrap';
import FormInput from '../../components/FormInput';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import { getContentStyles } from '../../helpers/util';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api } from '../../services/api';
import {
  getChildByApi,
  createChildApi,
  updateChildByApi,
  getContest,
} from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import ReactSelect from '../../components/ReactSelect';
import validate from 'validate.js';

const CreateChild = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _id = params.get('_id');

  const initialFormValues = {
    _id: '',
    contestName: '',
    childName: '',
    fatherName: '',
    motherName: '',
    mobileNumber: '',
    dateOfBirth: '',
    schoolName: '',
    isChildParticipatedInContest: false,
  };
  const [contestList, setContest] = useState([]);
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [editId, setEditId] = useState();
  const pageTitle = editId ? 'Edit Detail' : 'Register For Contest';
  const windowWidth = window.innerWidth;
  const navigate = useNavigate();
  const redirectToRegisterList = () => {
    navigate('/contestants');
  };


  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  const contentStyles = getContentStyles(sideMenuOpen, windowWidth);

  const getChildList = async (result) => {
    try {
      const { childListApi } = getChildByApi;
      const response = await api({
        ...childListApi,
        url: `${childListApi.url}/${_id}`,
      });
      const responseData = response.data;

      if (responseData) {
        const {
          childName,
          fatherName,
          motherName,
          dateOfBirth,
          schoolName,
          mobileNumber,
          contests,
        } = responseData;
        if (contests && contests.contestName) {
          const contestNameValue = result?.find(
            (x) => x?.value === contests.contestId
          );
          const formattedDateOfBirth = formatDateToYYYYMMDD(dateOfBirth);
          setFormValues({
            contestName:
              {
                label: contestNameValue?.label,
                value: contestNameValue?.value,
              } || '',
            childName: childName || '',
            fatherName: fatherName || '',
            motherName: motherName || '',
            mobileNumber: mobileNumber || '',
            dateOfBirth: formattedDateOfBirth || '',
            schoolName: schoolName || '',
          });
        }
      }
    } catch (error) {
      // Toast({type:'error', message:response.message});
    }
  };

  const getAllContest = async () => {
    try {
      const { getContestList } = getContest;
      const response = await api({ ...getContestList });

      if (response.status === 200) {
        let categories = response.data.map((cat) => ({
          label: cat.lable,
          value: cat._id,
        }));
        setContest(categories);
        return categories;
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllContest();
        if (_id) {
          setEditId(_id);
          getChildList(result);
        }
      } catch (error) {}
    };
    fetchData();
  }, [_id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { registerChildApi } = createChildApi;
    const { updateChildApi } = updateChildByApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        let response;
        if (editId) {
          response = await api({
            ...updateChildApi,
            url: `${updateChildApi.url}/${editId}`,
            body: {
              ...formValues,
              contestId: formValues.contestName.value,
            },
          });
        } else {
          response = await api({
            ...registerChildApi,

            body: {
              ...formValues,
              contestId: formValues.contestName.value,
            },
          });
        }

        if (response.status === 200 || response.status === 201) {
          Toast({
            type: 'success',
            message: editId
              ? 'Successfully Updated'
              : 'Successfully Registered for contest',
          });
          resetForm();
          navigate('/contestants');
        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
        }
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
      }
    }
  };
  function formatDateToYYYYMMDD(dateString) {
    let formattedDate = dateString;
    if (dateString.includes('/')) {
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const [year, month, day] = parts;
        formattedDate = `${year}-${month}-${day}`;
      }
    } else if (dateString.includes('-')) {
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const [year, month, day] = parts;
        formattedDate = `${year}-${month}-${day}`;
      }
    }
    return formattedDate;
  }
  return (
    <>
      <Header
        toggleSideMenu={toggleSideMenu}
        title={pageTitle}
        contentStyles={contentStyles}
      />
      <div className='create-page ' style={contentStyles}>
        <div className='container-fluid'>
          <Row>
            {/* <div className='col-lg-12'>
              <div className='page-title d-flex'>
                <button
                  type='button'
                  className=' cancel_btn'
                  onClick={redirectToRegisterList}
                >
                  <i className='fa fa-arrow-left'></i>
                </button>
              </div>
            </div> */}

            <Card>
              <CardBody className='create-body'>
                <Row>
                  <Col>
                    {/* <CardTitle className='h4 mb-4'>Register Child</CardTitle> */}
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col className='col-12' lg='3' md='3'>
                          <ReactSelect
                            name='contestName'
                            label='Choose Contest'
                            options={contestList}
                            formGroupClassName='mb-3'
                            labelClassName='col-lg-12 col-form-label'
                            placeholder='Select Contest'
                            value={formValues.contestName}
                            onChange={(...params) =>
                              handleInputChange(...params)
                            }
                            error={errors['contestName']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='text'
                            placeholder='Child Name'
                            icon='fa-user'
                            name='childName'
                            label='Child Name'
                            value={formValues.childName}
                            onChange={handleInputChange}
                            error={errors['childName']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='text'
                            placeholder='Mobile Number'
                            icon='fa-user'
                            name='mobileNumber'
                            label='Mobile Number'
                            value={formValues.mobileNumber}
                            onChange={handleInputChange}
                            error={errors['mobileNumber']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='date'
                            placeholder='Date of Birth'
                            icon='fa-user'
                            name='dateOfBirth'
                            label='Date of Birth'
                            value={formValues.dateOfBirth}
                            onChange={handleInputChange}
                            error={errors['dateOfBirth']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='text'
                            placeholder='Father Name'
                            icon='fa-user'
                            name='fatherName'
                            label='Father Name'
                            value={formValues.fatherName}
                            onChange={handleInputChange}
                            error={errors['fatherName']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='text'
                            placeholder='Mother Name'
                            icon='fa-user'
                            name='motherName'
                            label='Mother Name'
                            value={formValues.motherName}
                            onChange={handleInputChange}
                            error={errors['motherName']}
                          />
                        </Col>
                        <Col className='col-12' lg='3' md='3'>
                          <FormInput
                            type='text'
                            placeholder='School Name'
                            icon='fa-user'
                            name='schoolName'
                            label='School Name'
                            value={formValues.schoolName}
                            onChange={handleInputChange}
                            error={errors['schoolName']}
                          />
                        </Col>
                      </Row>
                      <Col lg={12}>
                        <div className='ml-auto'>
                          <div className='d-flex justify-content-end'>
                            <button
                              type='button'
                              className='cancel_btn'
                              onClick={redirectToRegisterList}
                            >
                              Cancel
                            </button>
                            <button
                              type='submit'
                              className='px-4 primary_btn mr-2'
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Col>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
      <SideMenu isOpen={sideMenuOpen} toggleSideMenu={toggleSideMenu} />
    </>
  );
};
export default CreateChild;
const validationRules = {
  contestName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  childName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  fatherName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  motherName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
};
