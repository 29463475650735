import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './pagination.scss'


export const Pagination = (props) => {
  // render() {
  const { pageMeta = {}, handlePageChange = {} } = props;

  return (
    <div className="d-flex justify-content-end pt-3">
      {pageMeta && pageMeta.totalPages > 1 ? (
        <ReactPaginate
          disableInitialCallback
          previousLabel={
            <i className="icon-circle-left fs-25 fw-500" />
          }
          nextLabel={<i className="icon-circle-right fs-25 fw-500" />}
          breakLabel={<span>...</span>}
          breakClassName="mx-2"
          pageCount={pageMeta.totalPages}
          pageRangeDisplayed={2}
          onPageChange={(e) => handlePageChange(e.selected + 1)}
          containerClassName="pagination custom-pagination mt-md-0 mt-3 mb-0"
          subContainerClassName="pages"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          activeClassName="active"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          forcePage={pageMeta?.page ? parseInt(pageMeta?.page, 10) - 1 : 0}
        />
      ) : null}
    </div>
  );
  // }
}

Pagination.propTypes = {
  pageMeta: PropTypes.node,
  handlePageChange: PropTypes.func,
};
Pagination.defaultProps = {
  pageMeta: {},
  handlePageChange: () => null,
};