import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import FormInput from '../../components/FormInput';
import validate from 'validate.js';
import { api } from '../../services/api';
import { loginApi } from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import Logo from '../../assets/img/miracleLogo.png';

const Login = ({setAuthenticated}) => {
  const navigate = useNavigate();
  const initialFormValues = {
    mobileNumber: '',
    password: '',
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [errors, setErrors] = useState({});
  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { loginFormApi } = loginApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        const response = await api({
          ...loginFormApi,
          body: {
            ...formValues,
          },
        });

        if (response.status === 200) {
          const { token } = response.data;

          localStorage.setItem('token', token);
         
          Toast({
            type: 'success',
            message: response.message
          });
          resetForm();
          setAuthenticated(true);
          navigate('/contestants');
        } else {
          Toast({
            type: 'error',
            message: response.message || 'Try again or call admin',
          });
        }
      } catch (error) {
        console.error('API Error:', error);
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
      }
    }
  };

  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  return (
    <>
      <div className='row align-items-center justify-content-center  basic-auth-container'>
        <div className='container'>
          <div className='col-lg-5 login-container'>
            <div className='d-flex align-items-center justify-content-center'>
              <img src={Logo} alt='login-avatar' width='120' height='120' />
            </div>
            <h2 className='title-header mt-4 text-center'>Welcome!</h2>

            <form onSubmit={handleSubmit}>
              <FormInput
                name='mobileNumber'
                type='text'
                label='Mobile Number'
                placeholder='mobileNumber'
                value={formValues.mobileNumber}
                onChange={handleInputChange}
                error={errors['mobileNumber']}
              />
              <div className='password-input-container'>
                <FormInput
                  name='password'
                  type='password'
                  label='Password'
                  placeholder='password'
                  value={formValues.password}
                  onChange={handleInputChange}
                  error={errors['password']}
                />
              </div>
              <div className='login-btn-section text-center'>
                <button
                  variant='primary'
                  className='w-100 py-2 login-btn '
                  type='submit'
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
const validationRules = {
  password: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },

  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        ' should be 10 digits long and contain only numbers',
    },
  },
};
