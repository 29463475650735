import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import { TableWrapper } from '../../components/TableWrapper';
import { getContentStyles } from '../../helpers/util';

import { Card, CardBody, Row, Col } from 'reactstrap';
import { api } from '../../services/api';
import { VotersApi } from '../../services/apiVariables';
import FormInput from '../../components/FormInput';

const VoteList = () => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [formData, setFormData] = useState({});
  const[isLoading,setIsLoading] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState('');
  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const contentStyles = getContentStyles(sideMenuOpen, windowWidth);
  const childDetailsHeader = [
    'S.No',
    'Child Name',
    'Contest Name',
    'Voters Count',
  ];
  const getVotersList = async () => {
    try {
      setIsLoading(true);
      const { votersCountApi } = VotersApi;
  
      const response = await api({
        ...votersCountApi,
        url: `${votersCountApi.url}?page=${page}&pageSize=${pageSize}&search=${searchCriteria}`,
      });
  
      const obj = {
        result: response.data.list,
        pageMeta: {
          page: response.data.pageMeta.page,
          pageSize: response.data.pageMeta.pageSize,
          totalPages: response.data.pageMeta.totalPages,
        },
      };
  
      setFormData(obj);
      setIsLoading(false);
    } catch (error) {
    }
  };
  
  useEffect(() => {
    getVotersList();
  }, [page]);

  const handleData = (keyValue, defaultValue, className = '') => {
    return (
      <td className={`${className}`}>
        {ternaryCondition(keyValue, keyValue, defaultValue)}
      </td>
    );
  };
  const handleSearchInputChange = (name, value) => {
    setSearchCriteria(value);
  };

  const handleSearchSubmit = (search) => {
    setSearchCriteria(search);
    setPage(1);
    getVotersList();
  };

  useEffect(() => {
    if (searchCriteria.trim() === '') {
      getVotersList(); 
      setPage(1);
    } 
  }, [searchCriteria]);
  return (
    <>
      <Header
        toggleSideMenu={toggleSideMenu}
        title={'Voters'}
        contentStyles={contentStyles}
      />
      <div className='page-content' style={contentStyles}>
        <div className='container-fluid'>
          <Row>
            <Card className='voters-list'>
              <CardBody>
                <Col>
                  <div className='search-container pb-0'>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                      <div className='d-flex align-items-center '>
                        <div className='input-container'>
                          <FormInput
                            type='text'
                            name='search'
                            label='Search'
                            placeholder='Search...'
                            onChange={handleSearchInputChange}
                            value={searchCriteria}
                            className='w-100'
                          />
                         
                        </div>
                        {searchCriteria && (
                          <button
                            onClick={() => handleSearchSubmit(searchCriteria)}
                            className='search-btn ml-2'
                          >
                            Search
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <TableWrapper
                    headerDetails={childDetailsHeader}
                    pageMeta={formData?.pageMeta}
                    handlePageChange={setPage}
                    listLength={formData?.result || []}
                    isLoading={isLoading}
                    highlightRows
                  >
                    {!isLoading && formData?.result?.map((item, index) => {
                      const {
                        childName = '',
                        contests: { contestName = '' },
                        votersCount = '',
                        _id = '',
                      } = item;

                      return (
                        <tr key={index}>
                          {handleData(
                            getSNo(
                              index,
                              ternaryCondition(
                                formData?.pageMeta,
                                formData?.pageMeta?.page,
                                1
                              ),
                              pageSize
                            ),
                            '-',
                            'text-center'
                          )}

                          {handleData(childName, '-', 'text-center')}
                          {handleData(contestName, '-', 'text-center')}
                          {handleData(votersCount, '-', 'text-center')}
                        </tr>
                      );
                    })}
                  </TableWrapper>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
      <SideMenu isOpen={sideMenuOpen} toggleSideMenu={toggleSideMenu} />
    </>
  );
};

export default VoteList;

export const ternaryCondition = (val1, val2 = '', defaultValue = '') => {
  return val1 ? val2 : defaultValue;
};
export const getSNo = (index, currentPage = 1, totalPages = 10) => {
  return parseInt((currentPage - 1) * totalPages, 10) + index + 1;
};
