

export const loginApi = {
  loginFormApi: {
    url: '/api/admin/user/login',
    method: 'post',
  },
};
export const registerApi = {
  registerListApi: {
    url: '/api/admin/child/list',
    method: 'get',
  },
};
export const VotersApi = {
  votersCountApi: {
    url: '/api/admin/child/voterlist',
    method: 'get',
  },
};

export const getChildByApi = {
  childListApi: {
    url: '/api/admin/register',
    method: 'get',
  },
};
export const createChildApi = {
  registerChildApi: {
    url: '/api/admin/register',
    method: 'post',
  },
};
export const updateChildByApi = {
  updateChildApi: {
    url: '/api/admin/child/',
    method: 'post',
  },
};

export const getContest = {
  getContestList: {
    url: '/api/admin/contest',
    method: 'get',
  },
};