import React,{useState,useEffect} from 'react';
import { Routes, Route,useNavigate } from 'react-router-dom';
import Login from '../pages/Auth/Login';
import ChildList from '../pages/RegisteredChild/ChildList';
import CreateChild from '../pages/RegisteredChild/CreateChild'
import VoteList from '../pages/Vote/VoteList';
import { Toast } from '../helpers/Toast';

const AuthRoutes = () => {
  const navigate = useNavigate()
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setAuthenticated(!!token);
  }, []);

  // Watch for changes in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      setAuthenticated(!!token);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  const handleUnauthorizedAccess = () => {
    Toast({
      type: 'error',
      message: 'unauthrozied',
    });
    return navigate('/')
  };

  return (
    <Routes>
      <Route path="/" element={<Login  setAuthenticated={setAuthenticated} />} />
   
      {authenticated ? (
        <>
          <Route path="/contestants" exact={true} element={<ChildList />} />
          <Route path="/createchild"  exact={true} element={<CreateChild />} />
          <Route path="/createchild/:id" element={<CreateChild />} />
          <Route path="/votelist"  exact={true} element={<VoteList />} />
        </>
      ) : (
        <Route
          path="*"
          element={handleUnauthorizedAccess}
        />
      )}
    </Routes>
  );
};



export default AuthRoutes;
