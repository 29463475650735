import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

const SideMenu = ({ isOpen, toggleSideMenu }) => {
  const location = useLocation();

  const isRouteActive = (route) => {
    return location.pathname === route;
  };
  const isActiveRoute = (currentPath, targetPaths) => {
    return targetPaths.some((path) => currentPath.includes(path));
  };
  const currentPath = location.pathname;
  const handleLogout = () => {
    localStorage.removeItem('token');
  };
  return (
    <div
      className={`sidemenu-wrapper ${isOpen ? '' : 'show'} d-none d-lg-block`}
    >
      <div className='sidemenu-content'>
        <div className='sidemenu-logo'>
          <a href='/'>
            <img
              src={require('../assets/img/newLogo.png')}
              alt='logo'
              className={isOpen ? '' : 'sidemenu-img'}
            />
          </a>
        </div>
        <button className='closeButton sideMenuCls' onClick={toggleSideMenu}>
          {/* <i className='far fa-times'></i> */}
          <MdKeyboardDoubleArrowRight />
        </button>
        <div className='vs-mobile-menu'>
          <ul>
            <li className={isOpen ? '' : 'hide-text'}>
              <NavLink
                exact
                to='/contestants'
                className={
                  isActiveRoute(currentPath, ['/contestants', '/createchild'])
                    ? 'active'
                    : ''
                }
              >
                <i
                  className={`fas fa-user ${
                    isActiveRoute(currentPath, ['/contestants', '/createchild'])
                      ? 'active-icon'
                      : ''
                  }`}
                ></i>
                <span>Contestants</span>
              </NavLink>
            </li>
            <li className={isOpen ? '' : 'hide-text'}>
              <NavLink exact to='/votelist' activeClassName='active'>
                <i
                  className={`fas fa-vote-yea ${
                    isRouteActive('/votelist') ? 'active-icon' : ''
                  }`}
                ></i>
                <span>Voters</span>
              </NavLink>
            </li>
            {/* <li className={isOpen ? '' : 'hide-text'}>
              <NavLink exact to='/votelist' activeClassName='active'>
                <i className={`fas fa-vote-yea ${isRouteActive('/votelist') ? 'active-icon' : ''}`}></i>
                <span>Voters</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className='sidemenu-footer'>
          <Link
            to='/'
            className={isOpen ? '' : 'hide-text'}
            onClick={handleLogout}
          >
            <i className='far fa-sign-out-alt'></i>
            <span className='ml-2'>Logout</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
