import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthRoutes from './routes/routes';


function App() {
  return (
    <Router>
      <div className="App">
        <AuthRoutes />
        
      </div>
    </Router>
  );
}

export default App;
