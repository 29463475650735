import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import { TableWrapper } from '../../components/TableWrapper';
import { getContentStyles } from '../../helpers/util';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaRegistered, FaTrash } from 'react-icons/fa';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { api } from '../../services/api';
import { registerApi } from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import FormInput from '../../components/FormInput';

const ChildList = () => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const navigate = useNavigate();
  const[isLoading,setIsLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [formData, setFormData] = useState({});

  const [sideMenuOpen, setSideMenuOpen] = useState(true);

  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };
  const handleRoute = (type, _id) => {
    const URL =
      type === 'CREATE' ? '/createchild' : `/createchild?_id=${_id}`;
    navigate(URL);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const contentStyles = getContentStyles(sideMenuOpen, windowWidth);
  const childDetailsHeader = [
    'S.No',
    'Contest Name',
    'Child Name',
    'Father Name',
    'Mother Name',
    'Mobile Number',
    'Date of Birth',
    'School Name',
    // 'Actions',
  ];
  const getChildList = async () => {
    
    const { registerListApi } = registerApi;
  
    try {
      setIsLoading(true);
      const response = await api({
        ...registerListApi,
        url: `${registerListApi.url}?page=${page}&pageSize=${pageSize}&search=${searchCriteria}`,
      });
  
      const obj = {
        result: response.data.list,
        pageMeta: {
          page: response.data.pageMeta.page,
          pageSize: response.data.pageMeta.pageSize,
          totalPages: response.data.pageMeta.totalPages,
        },
      };
  
      setFormData(obj);
      setIsLoading(false);
    } catch (error) {
    }
  };
  
  useEffect(() => {
    getChildList();
   
  }, [page]);

  const handleData = (keyValue, defaultValue, className = '') => {
    return (
      <td className={`${className}`}>
        {ternaryCondition(keyValue, keyValue, defaultValue)}
      </td>
    );
  };
  const handleSearchInputChange = (name, value) => {
    setSearchCriteria(value);
  };

  const handleSearchSubmit = (search) => {
    setSearchCriteria(search);
    setPage(1);
    getChildList();
  };

  useEffect(() => {
    if (searchCriteria.trim() === '') {
      getChildList(); 
      setPage(1);
    } 
  }, [searchCriteria]);
  return (
    <>
      <Header
        toggleSideMenu={toggleSideMenu}
        title={'Contestants'}
        contentStyles={contentStyles}
      />
      <div className='page-content' style={contentStyles}>
        <div className='container-fluid'>
          <Row>
            <Card className='card-list'>
              <CardBody>
                <Col>
                  <div className='search-container pb-2'>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                      <div className='d-flex align-items-center '>
                        <div
                          className='input-container'               
                        >
                          <FormInput
                            type='text'
                            name='search'
                            label='Search'
                            placeholder='Search...'
                            onChange={handleSearchInputChange}
                            value={searchCriteria}
                            className='w-100'
                          />
                           
                        </div>
                        {searchCriteria && (
                          <button
                            onClick={() => handleSearchSubmit(searchCriteria)}
                            className='search-btn ml-2'
                          >
                            Search
                          </button>
                        )}
                      </div>
                    </div>

                    {/* <div className='header-button'>
                      <button
                        onClick={() => handleRoute('CREATE')}
                        className='create-btn'
                      >
                        Register for Contest
                      </button>
                    </div> */}
                  </div>

                  <TableWrapper
                    headerDetails={childDetailsHeader}
                    pageMeta={formData?.pageMeta}
                    handlePageChange={setPage}
                    listLength={formData?.result || []}
                    isLoading={isLoading}
                    highlightRows
                  >
                    
                    {!isLoading && formData?.result?.map((item, index) => {
                      const {
                        contests: { contestName = '' },
                        childName = '',
                        fatherName = '',
                        motherName = '',
                        mobileNumber = '',
                        dateOfBirth = '',
                        schoolName = '',
                        _id = '',
                      } = item;

                      return (
                        <tr key={index}>
                          {handleData(
                            getSNo(
                              index,
                              ternaryCondition(
                                formData?.pageMeta,
                                formData?.pageMeta?.page,
                                1
                              ),
                              pageSize
                            ),
                            '-',
                            'text-center'
                          )}
                          {handleData(contestName, '-', 'text-center')}
                          {handleData(childName, '-', 'text-center')}
                          {handleData(fatherName, '-', 'text-center')}
                          {handleData(motherName, '-', 'text-center')}
                          {handleData(mobileNumber, '-', 'text-center')}
                          {handleData(dateOfBirth, '-', 'text-center')}
                          {handleData(schoolName, '-', 'text-center')}
                          {/* <td className='px-3 mx-auto text-center action-button'>
                            <div className='d-flex align-items-center justify-content-center'>
                              <button
                                variant='primary'
                                className='table-icon'
                                onClick={() => handleRoute('EDIT', _id)}
                              >
                                <FaEdit />
                              </button>
                            <button
                    className='table-icon'
                    // onClick={() => {
                    //   setDeleteId(item.id); // If 'id' is available
                    //   setPopupOpen(true);
                    // }}
                  >
                    <FaTrash /> 
                  </button>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </TableWrapper>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
      <SideMenu isOpen={sideMenuOpen} toggleSideMenu={toggleSideMenu} />
    </>
  );
};

export default ChildList;
export const ternaryCondition = (val1, val2 = '', defaultValue = '') => {
  return val1 ? val2 : defaultValue;
};
export const getSNo = (index, currentPage = 1, totalPages = 10) => {
  return parseInt((currentPage - 1) * totalPages, 10) + index + 1;
};
