import {
    axiosInstance,
    cancelTokenSource,
    statusHelper,
    getAuthToken,
  } from './utilities';
  import config from '../config';
  
  export const api = async function ({
    method = 'get',
    url: url,
    body,
    status = false,
    baseURL = config.api.BASE_URL,
    token = null,
    configObj = {},
  }) {
    const tempConfigObj = { ...configObj };
  
    if (cancelTokenSource.token)
      tempConfigObj.cancelToken = cancelTokenSource.token;
  
    const getValidToken = () => {
      if (token || getAuthToken()) {
        axiosInstance.defaults.headers.common.token = `${
          token || getAuthToken()
        }`;
      }
    };
  
    return await new Promise((resolve, reject) => {
      getValidToken();
  
      axiosInstance[method](`${baseURL}${url}`, body || '', tempConfigObj)
        .then((data) => {
          resolve(statusHelper(status, data));
        })
        .catch((error) => {
          try {
            if (error.response) {
              reject(statusHelper(status, error.response));
            } else {
              reject(error);
            }
          } catch (err) {
            reject(err);
          }
        });
    });
  };
  