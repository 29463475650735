import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { CommonTableLoader } from '../CommonTableLoader';
import { Pagination } from '../Pagination';
import './table.scss'

export class TableWrapper extends Component {


  handleRenderData = () => {
    const { children, listLength = [], headerDetails = [] } = this.props;
    return listLength.length !== 0 ?
      // { children }
      children
      : (
        <tr>
          <td colSpan={headerDetails.length}>
            <p className="py-5 fs-20 text-center text-primary line-height-23 fw-400">
              No records found !!!
            </p>
          </td>
        </tr>
      );
  };

  render() {
    const {
      headerDetails = [],
      pageMeta = {},  
      handlePageChange = {},
      isLoading = false,
      highlightRows = false,
      headerClassName = ""
    } = this.props;
    return (
      <div className={` custom-striped-table table-container ${highlightRows ? 'highlight' : ''}`}>
        <div className="maintable-content table-responsive">
          <table className="table">
            <thead>
              <tr>
                {headerDetails.map(label => (
                  <th className={headerClassName}>
                      {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                // <CommonTableLoader tdLength={headerDetails.length} />
                <div>Loading</div>
              ) : (
                this.handleRenderData()
              )}
            </tbody>
          </table>
        </div>

        <Pagination pageMeta={pageMeta} handlePageChange={handlePageChange} />
      </div>
    );
  }
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  // placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  headerDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  listLength: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  pageMeta: PropTypes.node,
  highlightRows: PropTypes.bool,
  handlePageChange: PropTypes.func,
  headerClassName: PropTypes.string
};
TableWrapper.defaultProps = {
  isLoading: false,
  headerDetails: [],
  listLength: [],
  pageMeta: {},
  highlightRows: false,
  handlePageChange: () => null,
  headerClassName : ''
}
