import React, { useState } from 'react';
import MobileMenu from './MobileMenu';

const Header = ({ title, contentStyles }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const CloseMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <>
      <header className='vs-header' id='page-topbar' style={contentStyles}>
        <div className='sticky-wrapper'>
          <div className='sticky-active'>
            <div className='header-menu-area'>
              <div className='container-fluid position-relative'>
                <div className='row gx-20 align-items-center justify-content-between'>
                  <div className='col-auto d-flex align-items-center'>
                    <button
                      type='button'
                      className='vs-menu-toggle d-block d-lg-none'
                      onClick={toggleMenu}
                    >
                      <i className='far fa-bars'></i>
                    </button>
                    <div className='header-title'>
                      <h1>{title}</h1>
                    </div>
                  </div>
                  <div className='col-auto  d-none d-sm-block'>
                    <div className='header-auth d-flex align-items-center'>
                    <i className='fas fa-user-alt'></i><h1>Welcome Admin</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isMenuOpen && <MobileMenu closeMenu={CloseMenu} />}
    </>
  );
};

export default Header;
