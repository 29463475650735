import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

const MobileMenu = ({ closeMenu }) => {
  const location = useLocation();

  const isRouteActive = (route) => {
    return location.pathname === route;
  };

  return (
    <>
      <div className='vs-menu-wrapper vs-body-visible' onClick={closeMenu}>
        <div className='vs-menu-area'>
          <button className='vs-menu-toggle' onClick={closeMenu}>
            <i className='fal fa-times'></i>
          </button>
          <div className='mobile-logo'>
            <a href='/'>
              <img src={require('../assets/img/miracleLogo.png')} alt='logo' />
            </a>
          </div>
          <div className='vs-mobile-menu'>
            <div className='mobile-auth d-flex align-items-center'>
              <i className='fas fa-user-alt'></i>
              <h1>Welcome Admin</h1>
            </div>
            <ul>
              <li>
                <NavLink exact to='/contestants' activeClassName='active'>
                  <i
                    className={`far fa-user ${
                      isRouteActive('/contestants') ? 'active-icon' : ''
                    }`}
                  ></i>
                  <span>Contestants</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to='/votelist' activeClassName='active'>
                  <i
                    className={`fas fa-vote-yea ${
                      isRouteActive('/votelist') ? 'active-icon' : ''
                    }`}
                  ></i>
                  <span> Voting</span>
                </NavLink>
              </li>
              <li>
                <Link to='/'>
                  <i className='far fa-sign-out-alt'></i>
                  <span className='ml-2'>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
